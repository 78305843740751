module.exports = [{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-4EPED7H1BZ","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Noun Graphisme Portfolio","short_name":"Noun Graphisme","start_url":"/","background_color":"#FFF","description":"Noun Graphisme Portfolio: Tous à propos de mes réalisations graphiques.","theme_color":"#FFF","display":"standalone","icon":"src/images/icon-512x512.png","icons":[{"src":"src/images/icon-512x512.png","type":"image/png","sizes":"512x512","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6d8c1a8295e3f2353a516cd291333d01"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
